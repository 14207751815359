import React from "react";



const Reviews = (props) => {
    return(
    <div className="elfsight-app-bfe13482-c084-4a44-938a-68e225255c47"></div>
    );
}

export default Reviews;